import React from "react";
import "./App.scss";
import { BrowserRouter, Route } from "react-router-dom";
import { Main } from "./Components/Main";
import { About } from "./Components/About";
import { Offer } from "./Components/Offer";
import { Contact } from "./Components/Contact";
import { Realization } from "./Components/Realization";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Route exact path="/" component={Main} />
        <Route exact path="/o-nas" component={About} />
        <Route exact path="/oferta" component={Offer} />
        <Route exact path="/realizacje" component={Realization} />
        <Route exact path="/kontakt" component={Contact} />
      </div>  
    </BrowserRouter>
  );
}

export default App;
