import React, { Component } from "react";
import { OfferItem } from "./OfferItem";
import { Translation } from "react-i18next";
import { NavMobile } from "./Nav_mobile";
import { Footer } from "./Footer";

class Offer extends Component {

  render() {
    return (
      <section className="offer" id="offer">
        <NavMobile />
        <Translation>{(t) => <h2 className="offer__header">{t('Nasza oferta')}</h2>}</Translation>
        <div className="offer__container">
        <Translation>{(t) => <OfferItem
            icon="fas fa-warehouse"
            title={t('Chłodnie')}
            paragraph={t('Chłodnie paragraf')}
          />}</Translation>
          <Translation>{(t) => <OfferItem
            icon="fas fa-snowflake"
            title={t('Mroźnie')}
            paragraph={t('Mroźnie paragraf')}
          />}</Translation>
            <Translation>{(t) => <OfferItem
            icon="fas fa-icicles"
            title={t('Instalacje chłodnicze')}
            paragraph={t('Instalacje chłodnicze paragraf')}
          />}</Translation>
        <Translation>{(t) => <OfferItem
            icon="fas fa-users-cog"
            title={t('Serwis chłodniczy')}
            paragraph={t('Serwis chłodniczy paragraf')}
          />}</Translation>
          <Translation>{(t) => <OfferItem
            icon="fas fa-lemon"
            title={t('Dojrzewalnie owoców')}
            paragraph={t('Dojrzewalnie owoców paragraf')}
          />}</Translation>
          <Translation>{(t) => <OfferItem
            icon="fas fa-wrench"
            title={t('Kontrolowana atmosfera')}
            paragraph={t('Kontrolowana atmosfera paragraf')}
          />}</Translation>
        </div>
        <Footer />
      </section>
    );
  }
}

export { Offer };
