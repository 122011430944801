import React from "react";
import { RowsPhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "react-photo-album/rows.css";
import { realizationsBase } from './RealizationsBase';

  export default function RealizationsGallery() {
    const [index, setIndex] = React.useState(-1);
  
    return (
      <>  
        <RowsPhotoAlbum
          photos={realizationsBase}
          targetRowHeight={350}
          onClick={({ index: current }) => setIndex(current)}
        />
  
        <Lightbox
          index={index}
          slides={realizationsBase}
          open={index >= 0}
          close={() => setIndex(-1)}
        />
      </>
    );
  }
