export const pinData = [
    {
        id: 0,
        latitude: 52.21,
        longitude: 20.62,
        description: "Frutim - zakres prac: chłodnictwo, sterowanie i sygnalizacja, konstrukcja hali, izolacja komór."
    },
    {
        id: 1,
        latitude: 51.93,
        longitude: 21.112,
        description: "Sun-sad - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-ochronna, KA."
    }, 
    {
        id: 2,
        latitude: 51.75,
        longitude: 20.76,
        description: "Agroserw - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-ochronna, KA."
    }, 
    {
        id: 3,
        latitude: 51.80,
        longitude: 20.44,
        description: "Gospodarstwo Sadownicze – Bogdan Biedrzycki - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-ochronna, KA."
    }, 
    {
        id: 4,
        latitude: 51.23,
        longitude: 18.55,
        description: "EKO Witamina - zakres prac: chłodnictwo, sterowanie, sygnalizacja"
    }, 
    {
        id: 5,
        latitude: 51.91,
        longitude: 21.08,
        description: "Polex - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-ochronna."
    }, 
    {
        id: 6,
        latitude: 49.57,
        longitude: 20.42,
        description: "Owoc Łącki - zakres prac: chłodnictwo, sterowanie, sygnalizacja."
    }, 
    {
        id: 7,
        latitude: 51.88,
        longitude: 20.82,
        description: "Gospodarstwo Sadownicze – Krzysztof Lipiński - zakres prac: kompletny obiekt."
    },  
    {
        id: 8,
        latitude: 51.78,
        longitude: 20.82,
        description: "Gospodarstwo Sadownicze – Grzegorz Feliksiak - zakres prac: budynek, posadzki, komory, technologia, sterowanie, sygnalizacja."
    }, 
    {
        id: 9,
        latitude: 52.26,
        longitude: 19.38,
        description: "Gospodarstwo Sadownicze Bartłomiej Rzymkowski - zakres prac: budynek, posadzki, komory, technologia, sterowanie, sygnalizacja."
    }, 
    {
        id: 10,
        latitude: 51.88,
        longitude: 20.83,
        description: "Gospodarstwo Sadownicze – Tomasz Jasiorowski - zakres prac: budynek, posadzki, komory, technologia, sterowanie, sygnalizacja."
    }, 
    {
        id: 11,
        latitude: 50.40,
        longitude: 18.90,
        description: "Agrosad – Tomasz Jasiorowski - zakres prac: chłodnictwo, sterowanie, sygnalizacja."
    }, 
    {
        id: 12,
        latitude: 50.83,
        longitude: 21.44,
        description: "Sadowniczy Zakład Doświadczalny Lipowa - zakres prac: chłodnictwo, sterowanie, sygnalizacja."
    }, 
    {
        id: 13,
        latitude: 47.43,
        longitude: 35.28,
        description: "Vasilivka - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna, KA."
    },
    {
        id: 14,
        latitude: 40.39,
        longitude: 49.78,
        description: "Baku - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna, KA."
    },
    {
        id: 15,
        latitude: 51.22,
        longitude: 24.68,
        description: "Kovel - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna, KA."
    },
    {
        id: 16,
        latitude: 51.27,
        longitude: 24.74,
        description: "Kovel - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna, KA."
    },
    {
        id: 17,
        latitude: 50.89,
        longitude: 21.64,
        description: "Targban - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 18,
        latitude: 51.15,
        longitude: 14.98,
        description: "Citronex - zakres prac: modernizacja obiektu."
    },
    {
        id: 19,
        latitude: 52.21,
        longitude: 20.83,
        description: "Del Monte - zakres prac: sterowanie i sygnalizacja."
    },
    {
        id: 20,
        latitude: 50.91,
        longitude: 21.66,
        description: "Targban - zakres prac: budynek, posadzki, komory, technologia, sterowanie i sygnalizacja."
    },
    {
        id: 21,
        latitude: 50.40,
        longitude: 16.76,
        description: "ITM Baza Poznańska - zakres prac: komory, technologia, sterowanie i sygnalizacja."
    },
    {
        id: 22,
        latitude: 52.19,
        longitude: 20.60,
        description: "W. Kosiorek P. Sioch - zakres prac: budynek, posadzki, komory, technologia, sterowanie i sygnalizacja."
    },
    {
        id: 23,
        latitude: 52.11,
        longitude: 20.62,
        description: "Czarpol - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 24,
        latitude: 52.21,
        longitude: 20.83,
        description: "Atlanta - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 25,
        latitude: 52.13,
        longitude: 20.64,
        description: "Czarpol - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 26,
        latitude: 52.23,
        longitude: 20.92,
        description: "Frutim - zakres prac: chłodnictwo, sterowanie, sygnalizacja, montaż komór chłodniczych."
    },
    {
        id: 27,
        latitude: 52.20,
        longitude: 20.61,
        description: "Frutim - zakres prac: chłodnictwo, sterowanie, sygnalizacja, montaż komór chłodniczych."
    },
    {
        id: 28,
        latitude: 52.25,
        longitude: 20.94,
        description: "Pelko - zakres prac: chłodnictwo, sterowanie, sygnalizacja."
    },
    {
        id: 29,
        latitude: 55.66,
        longitude: 37.0,
        description: "Moskwa - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 30,
        latitude: 46.36,
        longitude: 47.96,
        description: "Astrahań - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 31,
        latitude: 55.5,
        longitude: 38.0,
        description: "Moskwa - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 32,
        latitude: 51.53,
        longitude: 45.85,
        description: "Saratów - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 33,
        latitude: 55.45,
        longitude: 37.0,
        description: "Moskwa - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 34,
        latitude: 53.88,
        longitude: 27.52,
        description: "Mińsk - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 35,
        latitude: 54.2,
        longitude: 19.36,
        description: "E. Leclerc Polska - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 36,
        latitude: 50.79,
        longitude: 16.22,
        description: "Auchan Polska Sp. z o.o. - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 37,
        latitude: 49.96,
        longitude: 18.53,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 38,
        latitude: 49.81,
        longitude: 18.97,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 39,
        latitude: 52.24,
        longitude: 21.03,
        description: "Carrefour Polska - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 40,
        latitude: 52.30,
        longitude: 21.04,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 41,
        latitude: 51.52,
        longitude: 20.0,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 42,
        latitude: 51.66,
        longitude: 19.32,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 43,
        latitude: 52.18,
        longitude: 21.54,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 44,
        latitude: 51.75,
        longitude: 18.04,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 45,
        latitude: 49.98,
        longitude: 18.55,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 46,
        latitude: 52.23,
        longitude: 20.93,
        description: "Geant - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 47,
        latitude: 50.27,
        longitude: 19.12,
        description: "Geant - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 48,
        latitude: 52.36,
        longitude: 16.84,
        description: "Auchan Polska Sp. z o.o. - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 49,
        latitude: 50.81,
        longitude: 19.05,
        description: "Auchan Polska Sp. z o.o. - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 50,
        latitude: 51.77,
        longitude: 19.41,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 51,
        latitude: 51.79,
        longitude: 19.36,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 52,
        latitude: 53.01,
        longitude: 18.53,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 53,
        latitude: 51.77,
        longitude: 18.07,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 54,
        latitude: 52.44,
        longitude: 16.76,
        description: "Auchan Polska Sp. z o.o. - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 55,
        latitude: 50.20,
        longitude: 18.79,
        description: "Auchan Polska Sp. z o.o. - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 56,
        latitude: 53.13,
        longitude: 23.08,
        description: "Auchan Polska Sp. z o.o. - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 57,
        latitude: 51.13,
        longitude: 16.92,
        description: "Geant - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 58,
        latitude: 51.14,
        longitude: 21.0,
        description: "Geant - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 59,
        latitude: 52.13,
        longitude: 20.88,
        description: "Geant - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 60,
        latitude: 51.18,
        longitude: 17.0,
        description: "Carrefour Polska - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 61,
        latitude: 54.36,
        longitude: 18.55,
        description: "Auchan Polska Sp. z o.o. - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 62,
        latitude: 53.43,
        longitude: 14.48,
        description: "Makro Cash and Carry - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 63,
        latitude: 54.50,
        longitude: 18.40,
        description: "Makro Cash and Carry - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 64,
        latitude: 52.16,
        longitude: 22.24,
        description: "Zakład Mięsny „Wierzejki” - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 65,
        latitude: 52.21,
        longitude: 21.34,
        description: "POLSKAMP - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 66,
        latitude: 49.72,
        longitude: 19.1,
        description: "Zakład Mięsny „Koniarek” - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 67,
        latitude: 52.28,
        longitude: 21.53,
        description: "PFM Zakłady Mięsne Stanisławów Sp. z o.o. - zakres prac: chłodnictwo, sterowanie i sygnalizacja."
    },
    {
        id: 68,
        latitude: 52.31,
        longitude: 21.55,
        description: "„Farmers Meat” - zakres prac: chłodnictwo, sterowanie, sygnalizacja, izolacja rurociągów."
    },
    {
        id: 69,
        latitude: 54.06,
        longitude: 15.98,
        description: "Polana Żelimucha - zakres prac: chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna."
    },
    {
        id: 70,
        latitude: 50.52,
        longitude: 20.52,
        description: "Gomar - zakres prac: obudowa zimno-chronna."
    },
    {
        id: 71,
        latitude: 50.20,
        longitude: 23.87,
        description: "Pyriatyn - chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna."
    },
    {
        id: 72,
        latitude: 51.53,
        longitude: 22.84,
        description: "Polana Ochoża - chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna."
    },
    {
        id: 73,
        latitude: 53.35,
        longitude: 17.86,
        description: "Gospodarstwo Rolno-Ogrodnicze Łątka - chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna."
    },
    {
        id: 74,
        latitude: 50.46,
        longitude: 29.79,
        description: "Makarów - chłodnictwo, sterowanie, sygnalizacja."
    },
    {
        id: 75,
        latitude: 52.70,
        longitude: 21.04,
        description: "Negresco Sp. z o.o. - chłodnictwo, sterowanie, sygnalizacja."
    },
    {
        id: 76,
        latitude: 52.16,
        longitude: 20.91,
        description: "Dimond Busines Park BOC Logistic - chłodnictwo, sterowanie, sygnalizacja, montaż komór."
    },
    {
        id: 77,
        latitude: 52.92,
        longitude: 22.48,
        description: "SM Mlekowita - chłodnictwo, sterowanie, sygnalizacja, układ pośredni – glikolowy."
    },
    {
        id: 78,
        latitude: 51.32,
        longitude: 22.14,
        description: "Materne Polska - chłodnictwo, sterowanie, sygnalizacja, obudowa zimno-chronna."
    },
    {
        id: 79,
        latitude: 51.78,
        longitude: 21.05,
        description: "ABW Agrocentrum - chłodnictwo, sterowanie, sygnalizacja."
    },
    {
        id: 80,
        latitude: 53.17,
        longitude: 22.04,
        description: "Z. Wykowski - chłodnictwo, sterowanie, sygnalizacja."
    },
    {
        id: 81,
        latitude: 51.09,
        longitude: 22.34,
        description: "POW Krężnica Okrągła - chłodnictwo, sterowanie, sygnalizacja."
    },
    {
        id: 82,
        latitude: 50.43,
        longitude: 21.60,
        description: "K. Ciesielski - chłodnictwo, sterowanie, sygnalizacja."
    },
    {
        id: 83,
        latitude: 51.16,
        longitude: 21.61,
        description: '„Hortex” Holding S.A - chłodnictwo, sterowanie, sygnalizacja, izolacja zimno-ochronna.'
    },
    {
        id: 84,
        latitude: 53.82,
        longitude: 22.33,
        description: 'Frutex Eksportowa Spółdzielnia Przetwórstwa Owoców i Warzyw w Ełku - chłodnictwo, sterowanie, sygnalizacja, izolacja zimno-ochronna.'
    },
    {
        id: 85,
        latitude: 51.72,
        longitude: 15.55,
        description: 'PGR Kożuchów - chłodnictwo, sterowanie, sygnalizacja, montaż tuneli zamrażalniczych, izolacja zimno-ochronna.'
    },
    {
        id: 86,
        latitude: 53.31,
        longitude: 23.6,
        description: 'Matuszewski, Sokółka - chłodnictwo, sterowanie, sygnalizacja, montaż tuneli zamrażalniczych, izolacja zimno-ochronna.'
    },
    {
        id: 87,
        latitude: 50.3,
        longitude: 18.58,
        description: "Leader Price - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
    {
        id: 88,
        latitude: 51.41,
        longitude: 21.09,
        description: "E. Leclerc Polska - zakres prac: chłodnictwo, sterowanie i sygnalizacja, montaż komór i lad chłodniczych."
    },
]