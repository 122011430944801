import React from "react";
import {MapContainer, TileLayer} from 'react-leaflet'
import { Translation } from "react-i18next";
import { pinData } from "./PinsData";
import { Pin } from "./Pin";


function MapSection(props) {
    return (
        <section className="map">
            <Translation>{(t) => <h1 className="map__header" id='realizations'>{t('Szeroki zasięg działania')}</h1>}</Translation>
            <MapContainer center={[52.22, 20.92]} zoom={4.5} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {pinData.map( (e) => {
                    return (
                        <Pin key={e.id} description={e.description} latitude={e.latitude} longitude={e.longitude}/>
                    )
                })}
            </MapContainer>
        </section>
    );
}

export { MapSection };
