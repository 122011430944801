import React, { Component } from "react";
import RealizationsGallery from "./RealizationsGallery";
import { Translation } from "react-i18next";
import { Footer } from "./Footer";
import { NavMobile } from "./Nav_mobile";
import { MapSection } from "./MapSection";

class Realization extends Component  {
  render() {
    return (
      <section>
        <NavMobile />
        <div className="realizations">
          <Translation>{(t) => <h1 className="realizations__header" id='realizations'>{t('Realizacje')}</h1>}</Translation>
          <RealizationsGallery />
        </div>
        <MapSection />
        <Footer />
      </section>
    );
  }
}

export { Realization };
