import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "./../assets/imgs/mc-ele.png";
import uk from "./../assets/imgs/uk.png";
import pl from "./../assets/imgs/pl.png";
import ua from "./../assets/imgs/ua.png";
import i18n from "./i18n";
import { Translation } from "react-i18next";

function NavMobile() {
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState('pl');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  const isDesktopView = window.innerWidth > 1001;

  const mobileClosed =
    <nav className="nav__mobile">
      <Link to="/">
        <img
          src={logo}
          alt="MCELE LOGO"
          className="nav__mobile__main--logo"
        />
      </Link>
      <i className="fas fa-cogs" onClick={() => setOpen(!open)} />
    </nav>;

  const mobileOpen =
    <nav className="nav__mobile--open">
      <i onClick={() => setOpen(!open)} className="fas fa-times" />
      <ul className="nav__mobile__list">
        <li className="nav__mobile__list__elem">
          <Translation>{(t) =>
            <Link to="/o-nas">{t("O nas")}</Link>
          }</Translation>
        </li>
        <li className="nav__mobile__list__elem">
          <Translation>{(t) =>
            <Link to="/oferta">{t("Oferta")}</Link>
          }</Translation>
        </li>
        <li className="nav__mobile__list__elem">
          <Translation>{(t) =>
            <Link to="/realizacje">{t("Realizacje")}</Link>
          }</Translation>
        </li>
        <li className="nav__mobile__list__elem">
          <Translation>{(t) =>
            <Link to="/kontakt">{t("Kontakt")}</Link>
          }</Translation>
        </li>
        <li className="nav__mobile__list__elem">
          <div className="nav__mobile--flags">
            <div className="nav__mobile--left--flag">{lang !== 'en' ? <img
              src={uk}
              className="flag"
              alt="United Kingdom flag"
              onClick={() => changeLanguage('en')}
            /> : <img
              src={pl}
              className="flag"
              alt="Polish flag"
              onClick={() => changeLanguage('pl')}
            />}</div>
            <div>{lang !== 'ua' ? <img
              src={ua}
              className="flag"
              alt="Ukrainian flag"
              onClick={() => changeLanguage("ua")}
            /> : <img
              src={pl}
              className="flag"
              alt="Polish flag"
              onClick={() => changeLanguage("pl")}
            />}</div>
          </div>
        </li>
      </ul>
    </nav>

  const desktop =
    <nav className="nav__desktop">
      <Link to="/">
        <img src={logo} alt="Chłodnictwo logo" className="nav__mobile__main--logo" />
      </Link>
      <ul className="nav__mobile__list">
        <li className="nav__mobile__list__elem">
          <Translation>{(t) =>
            <Link to="/o-nas">{t("O nas")}</Link>
          }</Translation>
        </li>
        <li className="nav__mobile__list__elem">
          <Translation>{(t) =>
            <Link to="/oferta">{t("Oferta")}</Link>
          }</Translation>
        </li>
        <li className="nav__mobile__list__elem">
          <Translation>{(t) =>
            <Link to="/realizacje">{t("Realizacje")}</Link>
          }</Translation>
        </li>
        <li className="nav__mobile__list__elem">
          <Translation>{(t) =>
            <Link to="/kontakt">{t("Kontakt")}</Link>
          }</Translation>
        </li>
        <li className="nav__mobile__list__elem">
          {lang !== 'en' ? <img
            src={uk}
            className="flag"
            alt="United Kingdom flag"
            onClick={() => changeLanguage('en')}
          /> : <img
            src={pl}
            className="flag"
            alt="Polish flag"
            onClick={() => changeLanguage('pl')}
          />}
        </li>
        <li className="nav__mobile__list__elem">
          {lang !== 'ua' ? <img
            src={ua}
            className="flag"
            alt="Ukrainian flag"
            onClick={() => changeLanguage("ua")}
          /> : <img
            src={pl}
            className="flag"
            alt="Polish flag"
            onClick={() => changeLanguage("pl")}
          />}
        </li>
      </ul>
    </nav>

  function setMobileNav() {
    if (open) {
      return mobileOpen
    }
    return mobileClosed
  }

  return (
    isDesktopView ? desktop : setMobileNav()
  )
}

export { NavMobile };
