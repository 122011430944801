import React, { Component } from "react";
import { NavMobile } from "./Nav_mobile";
import { Footer } from "./Footer";
import { Translation } from "react-i18next";

class Main extends Component {
render() {
  return (
    <section className="main">
      <NavMobile />
      <div className="main__container">
        <div className="main__content">
          <p className="main__content--parag">
          <Translation>{(t) => <span className="initial">{t("inicjał1")}</span>}</Translation>
          <Translation>{(t) => <span>{t("glowna1")}</span>}</Translation>
          </p>
          <p className="main__content--parag">
          <Translation>{(t) => <span className="initial">{t("inicjał2")}</span>}</Translation>
          <Translation>{(t) => <span>{t("glowna2")}</span>}</Translation>
          </p>
        </div>
      </div>
      <Footer />
    </section>
  );
}
}

export { Main };
