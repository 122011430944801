import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "./../assets/imgs/mc-ele-mini.png";

class Footer extends Component {
  render() {
    return (
      <footer>
        <Link to="/">
        <img
                src={logo}
                alt="MCELE LOGO"
                className="footer__logo"
              />
        </Link>
        <span> &copy; 2024</span>
        <a
          href="http://aleksandra-kulesz.github.io/portfolio"
          target={"_blank"} rel="noreferrer">
          Made by A. Kulesz
        </a>
      </footer>
    );
  }
}

export { Footer };
