import React, { Component } from "react";
import { NavMobile } from "./Nav_mobile";
import { Footer } from "./Footer";
import { Translation } from "react-i18next";

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section className="about">
        <NavMobile />
        <div className="about__container">
          <div className="about__content"> 
          <Translation>{(t) => <h2 className="about__content--header">{t("O nas")}</h2>}</Translation>
          <Translation>{(t) => <p className="about__content--parag">
          {t("o nas paragraf")}
            </p>}</Translation>
          </div>
         </div>
        <Footer />
      </section>
    );
  }
}

export { About };
