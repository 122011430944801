import React from "react";

function OfferItem(props) {
  return (
    <div className="offer__item">
      <i className={props.icon}/>
      <h3 className="offer__item--title">{props.title}</h3>
      <p className="offer__item--paragraph">{props.paragraph}</p>
    </div>
  );
}

export { OfferItem };