import React from "react";
import { Marker, Popup } from 'react-leaflet'


function Pin(props) {
    return (
        <>
            <Marker position={[props.latitude, props.longitude]}>
                <Popup>
                    {props.description}
                </Popup>
            </Marker>
        </>
    );
}

export { Pin };
