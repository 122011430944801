import React from "react";
import { Translation } from "react-i18next";
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import { NavMobile } from "./Nav_mobile";
import { Footer } from "./Footer";


function Contact() {
  return (
    <>
      <section className="contact" id="contact">
        <NavMobile />
          <div className="contact__content">
            <div className="contact__info">
              <Translation>
                {(t) => (
                  <h2 className="contact__info--header">
                    {t("Bądźmy w kontakcie")}
                  </h2>
                )}
              </Translation>
              <p className="contact__info--name">
              CSG Systems sp. z o.o.
              </p>
              <p className="contact__info--address">
                ul.Kołłątaja 4, lok. U.2.1 <span>05-400 Otwock</span>
              </p>
              <p className="contact__info--phone"> +48 571 245 313 </p>
              <p className="contact__info--mail"> biuro@csgsystems.pl </p>
            </div>
            <div className="contact__map">
              <Translation>
                  {(t) => (
                    <h2 className="contact__map--header">
                      {t("Zajrzyj do nas!")}
                    </h2>
                  )}
              </Translation>
              <MapContainer className="contact__map--container" center={[52.1101, 21.2573]} zoom={17} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[52.1101, 21.2573]}>
                    <Popup>
                      Galeria Forum, Hugona Kołłątaja 4/lok.U.2.1, 05-400 Otwock
                    </Popup>
                  </Marker>
              </MapContainer>
            </div>
          </div>
      </section>
      <Footer/>
    </>
  );
}

export { Contact };