import realization1 from './../assets/realizations/realization1.jpg';
import realization2 from './../assets/realizations/realization2.jpg';
import realization3 from './../assets/realizations/realization3.jpg';
import realization4 from './../assets/realizations/realization4.jpg';
import realization5 from './../assets/realizations/realization5.jpg';
import realization6 from './../assets/realizations/realization6.jpg';
import realization7 from './../assets/realizations/realization7.jpg';


export const realizationsBase = [
  {
    id: 1,
    title: "Agroserw - Kozietuły",
    width: 3840,
    height: 2560,
    description: "2010 rok - Przechowalnia owoców - zakres prac: chłodnictwo (opracowanie własne, układ pośredni, 9 komór), sterowanie, sygnalizacja, obudowa zimno-ochronna, KA.",
    src: realization1,
    thumb: './../assets/realizations/realization1-fotor-20241007133444.png',
  },

  {
    id: 2,
    title: "Vasilivka (Василівка) - Ukraina",
    width: 3840,
    height: 2560,
    description: "2011 rok - Przechowalnia owoców - zakres prac: chłodnictwo (opracowanie własne, układ pośredni, 77 komór przechowalniczych), sterowanie, sygnalizacja, obudowa zimno-ochronna, KA.",
    src: realization2,
    thumb: './../assets/realizations/realization2-fotor-20241007133531.png',
  },

  {
    id: 3,
    title: "Targban - Ożarów",
    width: 3840,
    height: 2560,
    description: "2010 rok - Dojrzewalnia bananów - zakres prac: chłodnictwo (opracowanie własne, układ pół ciśnieniowy, 20 komór), sterowanie, sygnalizacja",
    src: realization3,
    thumb: './../assets/realizations/realization3-fotor-20241007134122.png',
  },

  {
    id: 4,
    title: "Moskwa - Rosja",
    width: 3840,
    height: 2560,
    description: "2009 rok - Dojrzewalnia bananów - zakres prac: chłodnictwo (opracowanie własne, układ pół ciśnieniowy, 20 komór), sterowanie, sygnalizacja",
    src: realization4,
    thumb: './../assets/realizations/realization4-fotor-20241007134034.png',
  },

  {
    id: 5,
    title: "E.Leclerc - Elbląg",
    width: 3840,
    height: 2560,
    description: "2004 rok - Supermarket - zakres prac: chłodnictwo (opracowanie własne), sterowanie, sygnalizacja",
    src: realization5,
    thumb: './../assets/realizations/realization5-fotor-20241007133730.png',
  },

  {
    id: 6,
    title: "POLSKAMP - Konik Stary",
    width: 3840,
    height: 2560,
    year: 2007,
    description: "2007 rok - Zakład mięsny - zakres prac: chłodnictwo (opracowanie własne), sterowanie, sygnalizacja",
    src: realization6,
    thumb: './../assets/realizations/realization6-fotor-2024100713359.png',
  },

  {
    id: 7,
    title: "Polana Żelimuchowa - Żelimucha",
    width: 3840,
    height: 2560,
    year: 2008,
    description: "2008 rok - Zakres prac: chłodnictwo (opracowanie własne), sterowanie, sygnalizacja, obudowa zimno-ochronna ",
    src: realization7,
    thumb: './../assets/realizations/realization7-fotor-20241007133558.png',
  },
];
